import { ICardMini } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { addToCart, getUserInfo } from "@/shared/redux/features/userSlice";
import { usePathname, useRouter } from "next/navigation";
import { clearItemsInfo, getCustomerInfo, setCustomerInfo } from "@/shared/redux/features/orderSlice";
import { Icon } from "@/shared/components/Icon";
interface IButtons {
  card: ICardMini;
  onClickAddToCart?: () => void;
}
const Buttons: FC<IButtons> = props => {
  const {
    card,
    onClickAddToCart
  } = props;
  const pathname = usePathname();
  const [isClient, setIsClient] = useState(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const currentStep = useAppSelector(state => state.order.step);
  const customer = useAppSelector(getCustomerInfo);
  const router = useRouter();
  const user = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const isInCart = user.info.cart.find((item: any) => item.product_id === card.id);
  const handleCartClick = async (e: any) => {
    e.preventDefault();
    setButtonDisable(true);
    try {
      if (isInCart) {
        router.push("/cart");
      } else {
        if (onClickAddToCart) {
          onClickAddToCart();
        }
        if (pathname === "/search/" && !isInCart) ym(42989679, "reachGoal", "addToCartFromBigSearch");
        const values = {
          name: customer.name ? customer.name : user.info.name,
          last_name: customer.last_name ? customer.last_name : user.info.lastname,
          tel: customer.tel ? customer.tel : user.info.phone,
          email: customer.email ? customer.email : user.info.email
        };
        if (currentStep >= 2) {
          dispatch(setCustomerInfo({
            step: 1,
            ...values
          }));
        }
        dispatch(addToCart({
          id: card.id,
          quantity: card.diff_pack && card.diff_pack.value ? Number(card.diff_pack.value) : 1,
          price: card.price_discount
        }));
        dispatch(clearItemsInfo());
      }
    } catch (error) {
      console.error("Error during cart operation:", error);
    } finally {
      setButtonDisable(false);
    }
  };
  useEffect(() => setIsClient(true), []);
  return <div className={styles.buttonWrap} data-sentry-component="Buttons" data-sentry-source-file="index.tsx">
      {!card.comission ? isClient ? card.available ? isInCart ? <button onClick={handleCartClick} className={styles.inCart}>
                <Icon name={"InCart"} />
              </button> : <button onClick={handleCartClick} className={styles.addToCart} disabled={buttonDisable} title={`Купить ${card.name}`}>
                <Icon name={"AddToCart"} />
              </button> : <button className={styles.isNotAvailable} disabled>
              <Icon name={"NotAvailable"} />
            </button> : <button onClick={handleCartClick} className={styles.addToCart} disabled={buttonDisable} title={`Купить ${card.name}`}>
            <Icon name={"AddToCart"} />
          </button> : <Link prefetch={false} href={`/card/${card.id}`} className={styles.showButton}>
          Посмотреть
        </Link>}
    </div>;
};
export default Buttons;