"use client";

import Link from "next/link";
import styles from "./index.module.scss";
import Image from "next/image";
import { FC, useState } from "react";
import { ICardMini } from "@/shared/types/types";
import { decodeString } from "@/utils/api/decodeString";
import { usePathname } from "next/navigation";
import Stars from "@/shared/components/Stars";
import Actions from "@/shared/components/CardMini/components/Actions";
import Labels from "@/shared/components/CardMini/components/Labels";
import Price from "@/shared/components/CardMini/components/Price";
import Buttons from "@/shared/components/CardMini/components/Buttons";
import SkeletonLoader from "@/shared/components/skeleton-loader";
import { li } from "@/utils/functions/li";
import clsx from "clsx";
import { useMediaQuery } from "@/hooks/useMediaQuery";
interface IProps {
  card: ICardMini;
  className?: string;
  onClick?: () => void;
  disable?: boolean;
  onClickFavourite?: () => void;
  onClickAddToCart?: () => void;
  isYellow?: boolean;
}
const CardMini: FC<IProps> = props => {
  const {
    card,
    className,
    onClick,
    disable = false,
    onClickFavourite,
    onClickAddToCart,
    isYellow = false
  } = props;
  const pathname = usePathname();
  const [isImageInit, setIsImageInit] = useState<boolean>(false);
  const isInCart = false;
  const isMobile = useMediaQuery("(max-width: 767px)");
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (pathname === "/search/") {
      ym(42989679, "reachGoal", "goingToCardFromBigSearch");
    }
  };
  return <div className={clsx(styles.wrap, isInCart && styles.inCart, className)} aria-disabled={disable} data-sentry-component="CardMini" data-sentry-source-file="index.tsx">
      <Actions card={card} onClickFavourite={onClickFavourite} data-sentry-element="Actions" data-sentry-source-file="index.tsx" />
      <Link prefetch={false} href={`/card/${card.id}/`} className={styles.link} onClick={handleClick} title={card.name} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Labels card={card} data-sentry-element="Labels" data-sentry-source-file="index.tsx" />
        <div className={clsx(styles.image_wrap)}>
          {!isImageInit ? <SkeletonLoader containerTag={"div"} className={styles.image_loader} width={""} height={""} /> : null}
          <Image className={card.comission ? styles.comissionImg : styles.img} alt={card.name} src={li(card.preview_picture)} width={500} height={500} itemProp="image" onLoad={() => setIsImageInit(true)} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        {!card.comission ? <div className={styles.reviews}>
            <Stars stars={card.reviews} isMobile={isMobile} />
            {card.reviews_count && card.reviews_count != 0 && <div className={styles.reviewsNumbers}>
                <p className={styles.reviewsRating}>{card.reviews}</p>
                <p className={styles.reviewsCount}>{card.reviews_count}</p>
              </div>}
          </div> : null}
        <p className={styles.name}>{decodeString(card.name)}</p>
      </Link>
      <div className={`${styles.bottom} ${card.comission && styles.comission}`}>
        <Price card={card} data-sentry-element="Price" data-sentry-source-file="index.tsx" />
        <Buttons card={card} onClickAddToCart={onClickAddToCart} data-sentry-element="Buttons" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default CardMini;