import styles from "./index.module.scss";
import { FC } from "react";
import { ICardMini } from "@/shared/types/types";
import Label from "@/shared/components/Label";
interface ILabels {
  card: ICardMini;
}
export const Labels: FC<ILabels> = props => {
  const {
    card
  } = props;
  const isNew = card.labels && card.labels.new;
  const countDiscount = card.labels && card.labels.discount && card.discount_percent >= 10 ? `-${card.discount_percent}%` : false;
  const isLicense = card.product_license && card.product_license;
  const firstWeapon = card.labels?.first_weapon;
  return (card.labels || isLicense) && <div className={styles.labelWrap}>
        {firstWeapon ? <Label text="Первое оружие" color={"green"} /> : null}
        {isLicense ? <Label text="Лицензионный товар" color={"beige"} /> : null}
        {isNew ? <Label text="Новинка" color={"white"} variant={"border"} /> : null}
        {countDiscount ? <Label text={countDiscount} color={"yellow"} /> : null}
      </div>;
};
export default Labels;