"use client";

import styles from "./index.module.scss";
import { FC, useState } from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { addToFavourites, getUserInfo } from "@/shared/redux/features/userSlice";
import { ICardMini } from "@/shared/types/types";
import { usePathname } from "next/navigation";
import { Icon } from "@/shared/components/Icon";
import { useAuthModal } from "@/hooks/useAuthModal";
interface IActions {
  card: ICardMini;
  onClickFavourite?: () => void;
}
const Actions: FC<IActions> = props => {
  const {
    card,
    onClickFavourite
  } = props;
  const pathname = usePathname();
  const {
    handleOpenAuthModal
  } = useAuthModal();
  const [isFavorites, setIsFavorites] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserInfo);
  const favouriteClassnames = classNames({
    [styles.icon]: true,
    [styles.active]: isFavorites
  });
  setTimeout(() => {
    setIsFavorites(user.info.favourites.includes(Number(card.id)));
  }, 100);
  const handleFavouriteClick = () => {
    if (!user.auth.isAuth) {
      handleOpenAuthModal();
    } else {
      if (onClickFavourite && !isFavorites) onClickFavourite();
      if (pathname === "/search/" && !isFavorites) ym(42989679, "reachGoal", "addToWishlistFromBigSearch");
      dispatch(addToFavourites({
        id: Number(card.id),
        price: card.price_discount
      }));
    }
  };
  return !card.comission && <>
        <div className={styles.actions}>
          <button onClick={handleFavouriteClick} className={styles.favourite} title={`Добавить в избранное ${card.name}`}>
            <Icon name={"Favourite"} className={favouriteClassnames} stroke="var(--grey-darker-color)" />
          </button>
        </div>
      </>;
};
export default Actions;