import styles from "./index.module.scss";
import { FC } from "react";
interface ISkeletonLoader {
  containerTag: string;
  baseColor?: string;
  highlightColor?: string;
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  duration?: number;
  enableAnimation?: boolean;
  className?: string;
  mb?: number | "";
  mt?: number | "";
  ml?: number | "auto" | "";
  mr?: number | "auto" | "";
}

/**
 * Компонент SkeletonLoader - универсальный скелетон-загрузчик для визуализации процесса загрузки контента.
 *
 * @param className - дополнительный класс для кастомизации стилей
 * @param baseColor - базовый цвет фона скелетона (по умолчанию "#f7f8f9")
 * @param highlightColor - цвет подсветки при анимации (по умолчанию "#1d1d1d")
 * @param height - высота скелетона, может быть числом (в пикселях), строкой с процентами или значением с единицами
 *   измерения (например, "100px")
 * @param width - ширина скелетона, может быть числом (в пикселях), строкой с процентами или значением с единицами
 *   измерения (например, "100px")
 * @param borderRadius - радиус скругления углов (по умолчанию 4)
 * @param duration - продолжительность анимации в секундах (по умолчанию 1)
 * @param enableAnimation - флаг для включения/отключения анимации (по умолчанию true)
 * @param containerTag - HTML-тег контейнера (по умолчанию "div")
 * @param mb - нижний отступ
 * @param mt - верхний отступ
 * @param ml - левый отступ
 * @param mr - правый отступ
 */

const SkeletonLoader: FC<ISkeletonLoader> = props => {
  const {
    className,
    baseColor = "#f7f8f9",
    highlightColor = "#1d1d1d",
    height = "100%",
    width = "100%",
    borderRadius = 4,
    duration = 1,
    enableAnimation = true,
    containerTag = "div",
    mb,
    mt,
    ml,
    mr
  } = props;
  return <>
      {{
      div: <div className={`
            ${className}
            ${styles.skeleton}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: !mb ? "" : `${mb}px`,
        marginTop: !mt ? "" : `${mt}px`,
        marginLeft: ml === "auto" ? "auto" : `${ml}px`,
        marginRight: mr === "auto" ? "auto" : `${mr}px`
      }}>
              <div className={`
                  ${styles.skeleton_highlight} 
                  ${enableAnimation && styles.animated}
                `} />
            </div>,
      li: <li className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9"
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </li>,
      p: <p className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </p>,
      a: <p className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </p>,
      span: <span className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </span>,
      h1: <h1 className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <span className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </h1>,
      h2: <h2 className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <span className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </h2>
    }[containerTag]}
    </>;
};
export default SkeletonLoader;